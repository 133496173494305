import ChevronDownIcon from "@heroicons/react/20/solid/ChevronDownIcon";
import Check from "@mui/icons-material/Check";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Slider from "@mui/material/Slider";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { styled as styling } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import withTheme from "@mui/styles/withTheme";
import styled from "styled-components";
import Color from "../../../assets/colors";
import { FontSize } from "../../../assets/font";
import FilterSVG from "../../../assets/svg/filter-icon.svg";
import InternshipSVG from "../../../assets/svg/internship-icon.svg";

// Scrollbar Component //
export const useStyles = makeStyles((theme) => ({
  scrollBar: {
    "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
      backgroundColor: "transparent",
      width: 3,
      overflow: "auto",
    },
    "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
      borderRadius: 8,
      backgroundColor: Color.hiredlyPurple,
      overflow: "auto",
    },
    "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
      borderRadius: 8,
      backgroundColor: Color.lightHiredlyPurple,
      overflow: "auto",
    },
    "&::-webkit-scrollbar-track": {
      marginTop: "1.5rem",
      marginBottom: "1.5rem",
      height: "3.25rem",
    },
  },
}));

// Container Components //
export const JobSearchOptionsWrapper = styling(Box)({
  width: "100%",
});

export const BoxContainer = styling(Box)({
  display: "flex",
  width: "100%",
});

export const JobSearchOptionsMenuBox = styling(Box)({
  width: "100%",
});

export const SliderContainer = styling(Grid)({
  width: "100%",
  maxWidth: "18.75rem",
  margin: "auto",
  padding: "1.25rem 0",
});

export const ButtonContainer = styling(Box)({
  display: "flex",

  backgroundColor: Color.white,
  borderRadius: "1rem",

  width: "100%",
  zIndex: 10,
});

// Checkbox Component //
export const CheckboxContainer = styling(ButtonBase)({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "0.4375rem",

  padding: "0.125rem 0.75rem 0.125rem 1rem",
  width: "100%",

  "&:hover": {
    cursor: "pointer",
  },
});

export const CheckboxStyled = styling(Checkbox)({
  "& .Mui-checked": {
    color: Color.black,
  },

  "&:hover": {
    background: "transparent",
  },
});

export const UncheckedIcon = styling(Box)({
  width: "18px",
  height: "18px",
  borderRadius: "0.25rem",
  border: "1px solid" + Color.black,
});

export const CheckedIcon = styling(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "18px",
  height: "18px",
  borderRadius: "0.25rem",
  border: "1px solid" + Color.black,
  backgroundColor: Color.black,
});

// Typography Components //
export const TextStyled = styling(Typography)({});

export const LabelStyled = withTheme(styled(Typography)`
  && {
    font-size: 0.875rem;
    text-transform: capitalize;
  }
`);

export const CountContainer = styling(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  backgroundColor: Color.lightHiredlyPurple,
  borderRadius: "0.25rem",
  border: `0.8px solid ${Color.hiredlyPurple}`,

  fontSize: "0.875rem",
  lineHeight: "1.5rem",

  height: "1.5rem",
  padding: "0 0.5rem",

  margin: "0",
});

export const ContentTextStyled = styling(Typography)({
  textTransform: "capitalize",
  justifyContent: "center",
  display: "flex",
  fontSize: FontSize.desktopCaption,
  fontWeight: "bold",
  letterSpacing: "0.03rem",
});

export const SnackbarTextStyled = styling(Typography)({
  fontSize: "0.875rem",
  lineHeight: "1.15rem",
  marginBottom: "0.45rem",
});

export const LinkStyled = styling(Link)({
  fontSize: "0.875rem",
  fontWeight: "600",
  fontSize: "0.65rem",
  cursor: "pointer",
});

// Button Component //
export const ButtonStyled = styling(ButtonBase)((props) => ({
  display: "flex",
  flexShrink: 1,
  flexWrap: "nowrap",
  gap: "0.5rem",

  color: props.$active ? Color.hiredlyPurple : Color.darkGrey,
  backgroundColor: props.$active ? Color.lightHiredlyPurple : Color.white,
  border: props.$active
    ? `1px solid ${Color.lightHiredlyPurple}`
    : `1px solid ${Color.secondaryGrey}`,
  boxShadow: props.$active ? `0px 0px 0px 2px ${Color.hiredlyPurple}` : "none",
  borderRadius: "0.5rem",

  fontFamily: "Inter",
  fontSize: "0.875rem",
  textTransform: "capitalize",
  lineHeight: "1.5rem",
  letterSpacing: "0.15px",
  whiteSpace: "nowrap",

  height: "2.25rem",
  padding: props.$jobPreference ? "0 0.375rem 0 0.625rem" : "0 0.625rem",

  transition:
    "all 0.15s ease-in-out, border 0.025s ease-in-out, box-shadow 0.175s ease-in-out",

  "@media (min-width: 1280px)": {
    "&:hover": {
      color: Color.black,
      backgroundColor: Color.white,
      border: `1px solid ${Color.white}`,
      boxShadow: "0px 0px 0px 2px rgba(0, 0, 0, 0.68)",

      "* > .MuiSwitch-track": {
        backgroundColor: "rgba(0, 0, 0, 0.68)",
      },
    },
  },

  "@media (max-width: 1280px)": {
    height: "2.5rem",
  },

  "@media (max-width: 640px)": {
    flex: "1",
    minWidth: "fit-content",
    width: "100%",
  },
}));

export const FilterActionButton = styling(ButtonBase)((props) => ({
  backgroundColor: "transparent",
  color: Color.hiredlyPurple,

  fontFamily: "Inter",
  fontSize: "0.875rem",
  fontWeight: "700",
  lineHeight: "1.5rem",
  letterSpacing: "0.1px",

  padding: "0",
  margin: "0",

  transition: "all 0.15s ease-in-out",

  "&:hover": {
    cursor: "pointer",
  },

  ...((props.variant === "filled" || props.variant === "outline") && {
    border: "1px solid #2e2e2e",
    borderRadius: "6.25rem",

    fontSize: "1rem",

    height: "2.625rem",
    width: "7.5rem",
  }),

  ...(props.variant === "filled" && {
    backgroundColor: "#2e2e2e",
    color: Color.white,
    "&:hover": {
      backgroundColor: Color.black,
      cursor: "pointer",
    },
  }),

  ...(props.variant === "outline" && {
    backgroundColor: Color.white,
    color: Color.black,
    "&:hover": {
      backgroundColor: Color.hiredlyPurple,
      color: Color.white,
      cursor: "pointer",
    },
  }),
}));

// Switch Component //
export const JobPreferenceToggle = styling(Switch)((props) => ({
  width: "2.75rem",
  height: "1.5rem",
  padding: "0",

  "& .MuiSwitch-switchBase": {
    padding: "0",
    margin: "0.2rem",
    transitionDuration: "125ms",

    "&.Mui-checked": {
      transform: "translateX(1.25rem)",
      color: Color.white,

      "& + .MuiSwitch-track": {
        backgroundColor: Color.hiredlyPurple,
        opacity: "1",
        border: "0",
      },

      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: "0.5",
      },
    },

    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: Color.hiredlyPurple,
      border: "6px solid #fff",
    },

    "&.Mui-disabled .MuiSwitch-thumb": {
      color: Color.darkGrey,
    },

    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: "0.5",
    },
  },

  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: "1.1rem",
    height: "1.1rem",
  },

  "& .MuiSwitch-track": {
    borderRadius: "2rem",
    backgroundColor: Color.grey,
    opacity: "1",
    transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1)",
  },
}));

// Menu Components //
export const FilterMenu = styling(Menu)((props) => ({
  overflowX: "hidden",
  overflowY: "hidden",

  "& .MuiMenu-paper": {
    width: "23rem",

    padding: "1rem",
    border: `0.125rem solid ${Color.black}`,

    [props.theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  //Target the menu container only
  ".MuiPaper-elevation8": {
    top: `${props.$position.top + 48}px !important`,
  },
  [props.theme.breakpoints.up(768)]: {
    ".MuiPaper-elevation8": {
      top: `${props.$position.top + 50}px !important`,
    },
  },
  [props.theme.breakpoints.up(1280)]: {
    ".MuiPaper-elevation8": {
      top: `${props.$position.top + 50}px !important`,
    },
  },
  ".MuiList-root": {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },

  "& .MuiPaper-rounded": {
    borderRadius: "1.25rem",
    marginBottom: "1rem",
  },

  "& .MuiList-root": {
    padding: "0",
  },

  "& .MuiMenuItem-root": {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

export const FilterMenuItem = styling(MenuItem)({
  padding: "0.5 0",
});

export const MenuStyled = styling(Menu)({
  "& .MuiMenu-paper": {
    minWidth: "28.25rem",
    maxWidth: "28.25rem",
    maxHeight: "34.5rem",
    padding: "1rem 0.5rem 0 0.5rem",
  },

  "& .MuiPaper-rounded": {
    borderRadius: "1.25rem",
  },

  "& .MuiList-root": {
    padding: "0",
  },
});

export const MenuItemStyled = styling(MenuItem)({
  padding: "0 0.5rem",

  "& fieldset": {
    width: "100%",

    "& label": {
      margin: "0",
    },
  },
});

// Icon Components //
export const FilterIcon = styling(FilterSVG)({
  height: "20px",
  width: "20px",
  padding: "0.25rem",
});

export const InternshipIcon = styling(InternshipSVG)({
  height: "20px",
  width: "20px",
});

export const ExpandMoreStyled = styling(ExpandMoreIcon)({
  width: "1.25rem",
  height: "1.25rem",
});

export const CheckStyled = styling(Check)({
  color: Color.white,
  width: "14px",
  height: "14px",
});

export const InfoIconStyled = styling(InfoIcon)((props) => ({
  color: Color.black,
  width: "1rem",
  height: "1rem",
  cursor: "pointer",

  [props.theme.breakpoints.down("tablet")]: {
    width: "1.15rem",
    height: "1.15rem",
  },
}));

// Slider Component //
export const SliderStyled = styling(Slider)({
  marginTop: "0.75rem",
  margin: "auto",

  "& .MuiSlider-rail": {
    backgroundColor: Color.black,
  },

  "& .MuiSlider-thumb": {
    backgroundColor: Color.black,
  },
});

//Badge Component//
export const BadgeStyled = styling(Badge)({
  "& .MuiBadge-badge": {
    height: "1.5rem",
    width: "1.5rem",
    borderRadius: "0.25rem",

    fontSize: "0.75rem",
    fontWeight: "400",
  },

  "@media (max-width: 640px)": {
    flex: "1",
    minWidth: "fit-content",
    width: "100%",
  },
});

export const BetaTextStyled = styling(Typography)({
  color: Color.blue,
  fontSize: "0.5rem",
  fontWeight: "bold",
  letterSpacing: "0.025rem",
});

//Tooltip Component//
export const TooltipStyled = styling(Tooltip)({});

// Select Component //
export const DropdownContainer = styling("div")({
  display: "flex",
  flexDirection: "column",
  gap: "0.25rem",
});

export const DropdownHeader = styling("button")((props) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",

  backgroundColor: Color.white,
  border: props.open
    ? "2px solid" + Color.hiredlyPurple
    : "2px solid" + Color.grey,
  borderRadius: "0.5rem",

  height: "2.5rem",
  padding: "0 1.25rem",

  transition: "all 0.2s ease-in-out",

  ...(props.$active && {
    border: "2px solid" + Color.hiredlyPurple,
  }),

  "&:hover": {
    cursor: "pointer",
    border: "2px solid" + Color.hiredlyPurple,

    "> svg": {
      color: Color.hiredlyPurple,
    },
  },
}));

export const DropdownOptionContainer = styling("div")((props) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",

  backgroundColor: Color.white,
  borderRadius: "0.5rem",

  overflowY: "auto",
  overflowX: "clip",
  maxHeight: "0",
  border: "2px solid transparent",
  marginTop: "-0.5rem",
  opacity: "0",
  transition:
    "max-height 0.2s ease-in-out, border 0.15s ease-in-out, opacity 0.15s ease-in-out, margin-top 0.15s ease-in-out",

  ...(props.open && {
    maxHeight: props?.noScroll ? "35rem" : "22.65rem",
    border: "2px solid" + Color.hiredlyPurple,
    marginTop: "0",
    opacity: "1",
  }),
}));

export const SelectIcon = styling(ChevronDownIcon)((props) => ({
  color: props.open ? Color.hiredlyPurple : Color.darkGrey,

  width: "20px",
  height: "20px",

  transform: props.open ? "rotate(-180deg)" : "rotate(0deg)",
  transition: "all 0.2s ease-in-out",
}));
